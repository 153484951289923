import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';

export default function AddWorkerModalLP() {                   //добавление работы, доступное обычному админу, можно добавлять только мойщиков 1 и 2 ранга
  
const [newWorker, setNewWorker]=useState([]);
const api_url = process.env.REACT_APP_API_URL;
const complex_name = localStorage.getItem('complex_name');
const [saveStatus, setSaveStatus] = useState("");
const [saveButtonDisable, setSaveButtonDisable] = useState(true);                    //по умолчанию кнопка сабмит выключена, проверяем, что номер телефона введен верно, после этого включаем ее 
const availableRoles = [{role_value: "car_washer", role_label:"Мойщик"}, {role_value: "quality_control", role_label:"Контроль качества"}, {role_value: "cashier", role_label:"Кассир"}]
const [selectedRoles, setSelectedRoles] = useState([]);
// const [complexName, setComplexName] = useState([]);

function handleChange(e) {
    setNewWorker({
      ...newWorker,
      [e.target.name]: e.target.value
    });
}

async function createWorker() {
    
    setSaveButtonDisable(true);                               //сразу выключаю кнопку сохранения, чтобы не нажимали дважды

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
        body: JSON.stringify({phone_number: newWorker.phoneNumber, full_name: newWorker.fullName, rank: newWorker.rank, complex_name: complex_name, role: "car_washer"})
    };

    const saving_visit = await fetch(api_url+"/api/addWorker", requestOptions);

    const body = await saving_visit.json();
    if (body.message === "Worker added successfully!") {
      setSaveStatus("success");
    }
    else {
      setSaveStatus("error");
      setSaveButtonDisable(false);
    }
}

    React.useEffect(() => {                           //тут отслеживаем, чтобы длина номера телефона соответствовала регекспу
        setSaveButtonDisable(!/^\+\d{11}$/.test(newWorker.phoneNumber));       //регексп на проверку того, чтобы номер телефона был +79111234567
    }, [newWorker.phoneNumber])

return (
    <div className='edit-worker-modal'>
    <h1 className="modal-header"> Добавить сотрудника </h1>
      <Box sx={{ maxWidth: 550, margin: `auto`, pt: 5, pb: 5 }}>

        <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
        <InputLabel htmlFor="component-outlined">ФИО сотрудника</InputLabel>
        <OutlinedInput
          id="worker_name_input"
          name="fullName"
          value={newWorker.fullName}
          onChange={handleChange}
          label="ФИО сотрудника"
        />
        </FormControl>

        <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
        <InputLabel id="demo-simple-select-label">Ранг</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="rank_chosing"
          name="rank"
          value={newWorker.rank}
          label="Ранг"
          onChange={handleChange}
        >
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
        </Select>
        </FormControl>


        <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
        <InputLabel htmlFor="component-outlined">Номер телефона сотрудника</InputLabel>
        <OutlinedInput
          id="worker_phone_input"
          name="phoneNumber"
          value={newWorker.phoneNumber}
          onChange={handleChange}
          label="Номер телефона сотрудника"
        />
        <FormHelperText id="phone-helper-text">Номер телефона в формате +79314445566</FormHelperText>
        </FormControl>

        {/* <FormControl sx={{mb:1.5, mt:1.5}} fullWidth size="small">
          <InputLabel id="demo-multiple-checkbox-label1">Комплексы:</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="complex-name"
            value={complexName}
            label="Комплексы:"
            name="complexChoosing"
            fullWidth
            onChange={e => setComplexName(e.target.value)}
            multiple
            input={<OutlinedInput id="select-multiple-chip" label="Комплексы:" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >             
            <MenuItem value="Охта">Охта</MenuItem>
            <MenuItem value="Кудрово">Кудрово</MenuItem>
          </Select>
        </FormControl> */}

        <Button variant="contained"
            sx={{mb:1.5, mt:1.5}} fullWidth
            disabled={saveButtonDisable}
            color="success"
            className="mr10"
            onClick={() => createWorker()}>Добавить сотрудника</Button>
        {saveStatus === "error" && <Alert severity="error">Ошибка, проверьте правильность введенных данных!</Alert>}
        {saveStatus === "success" && <Alert severity="success">Изменения успешно сохранены!</Alert>}
        </Box>
    </div>
)}